
<template>
	<el-form ref="form" :model="form" label-width="80px">
		<div
				v-for="(ite, indexTo) in formItems"
				:key="indexTo"
				style="margin-left: 5%"
		>
			<div v-if="ite.DdItemContentValue !== '学习设置'">
				<template>
					<div >{{ ite.DdItemContentValue }}</div>
				</template>
				<el-form-item
						v-for="(item, index) in ite.Children"
						:key="index"
						:label="item.DdItemContentValue"
						label-width="180px"
						label-position = "left"
				>
					<template v-if="item.DdItemTypeValue === 'radio'">
						<el-radio-group v-model="form[item.DdItemContentKey].value" @change="changeValue(item.DdItemContentValue,$event)" >
							<el-radio
									v-for="(option, optionIndex) in item.Children"
									:key="optionIndex"
									:label="option.DdItemContentKey"
							>
								{{ option.DdItemContentValue }}
							</el-radio>
						</el-radio-group>
					</template>
					<template v-if="item.DdItemTypeValue === 'cascader'">
						<el-cascader
								v-model="form[item.DdItemContentKey].value"
								:options="item.Data.Children"
								@change="handleChange"
								clearable
								style="width: 40%"
								:props="{ value: 'Code', label: 'Name', children: 'Children', checkStrictly: true}"
						></el-cascader>
					</template>
					<template v-if="item.DdItemTypeValue === 'checkbox'">
						<div >
							<el-checkbox-group v-model="form[item.DdItemContentKey].value"  >
								<el-checkbox
										v-for="(option, optionIndex) in item.Children"
										:key="optionIndex"
										:label="option.DdItemContentKey"
										:disabled='!isShowChange'
								>
									{{ option.DdItemContentValue }}
								</el-checkbox>
							</el-checkbox-group>
						</div>
					</template>
					<template v-if="item.DdItemTypeValue === 'input'">
						<el-input
								v-model="form[item.DdItemContentKey].value"
								style="width: 40%"
						/>
					</template>
					<template v-if="item.DdItemTypeValue === 'radioInput'">
						<el-radio-group v-model="form[item.DdItemContentKey].value" @change="changeValue(item,$event)">
							<el-radio
									v-for="(option, optionIndex) in item.Children"
									:key="optionIndex"
									:label="option.DdItemContentKey"
							>
								{{ option.DdItemContentValue }}
							</el-radio>
						</el-radio-group>
						<br />
						<el-input
								type="textarea"
								:rows="4"
								:disabled="isToChange"
								v-model="form[item.DdItemContentKey].remark"
								style="width: 40%"
						/>
					</template>
					<template v-if="item.DdItemTypeValue === 'picker'">
						<el-date-picker
								v-model="form[item.DdItemContentKey].value"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd"
								style="width: 40%"
						/>
					</template>
					<template v-if="item.DdItemTypeValue === 'select'">
						<el-select v-model="form[item.DdItemContentKey].value" :disabled='(item.DdItemContentValue ==="是否实人认证")&&!isShowChange' placeholder="请选择" style="width: 40%">
							<el-option
									v-for="item in item.Children"
									:key="item.Id"
									:label="item.DdItemContentValue"
									:value="item.DdItemContentKey"
									
							>
							</el-option>
						</el-select>
					</template>
					<template v-if="item.DdItemTypeValue === 'uploader'">
						<el-upload
								:action="actionUrl"
								:headers="headers"
								ref="upload"
								:before-upload="beforeUpload"
								:on-preview="handlePictureCardPreview"
								:on-success="(response, file) => handle_success(response, file, item)"
								:show-file-list="false"
								list-type="picture-card"
								:on-remove="handleRemove2"
								:on-change="handleChange"

						>
							<img v-if="form[item.DdItemContentKey].value"  :src="form[item.DdItemContentKey].value"  class="avatar"  alt="#"/>
							<i v-else  class="el-icon-plus"></i>
						</el-upload>
					</template>
					<template v-if="item.DdItemTypeValue === 'elButton'">
						<el-popover
								placement="right"
								width="300"
								trigger="click"
								popper-class="popperClass"
						>
							<div style="height: 350px;display: flex;align-items: center;justify-content: center;">
								<div style="">
									<el-image :src="httpsUrl" style="width: 300px; height: 300px;"></el-image>
								</div>
							</div>
							<div style="margin-left: 40%;">
								<el-button type="text" @click="downloadUrl">保存二维码</el-button>
								<!--							<a href="javascript:" @click="downloadUrl">下载二维码</a>-->
							</div>
							<el-button slot="reference"  class="circle-button" type="text" @click="">查看二维码</el-button>
						</el-popover>
					</template>
				
				</el-form-item>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
			</div>
			<div v-if="ite.DdItemContentValue === '学习设置'">
				<div v-if="isShowDialog">
					<div>{{ ite.DdItemContentValue }}</div>
					<el-form-item
							v-for="(item, index) in ite.Children"
							:key="index"
							:label="item.DdItemContentValue"
							label-width="180px"
							label-position = "left"
					>
						<template v-if="item.DdItemTypeValue === 'radio'">
							<el-radio-group v-model="form[item.DdItemContentKey].value" >
								<el-radio
										v-for="(option, optionIndex) in item.Children"
										:key="optionIndex"
										:label="option.DdItemContentKey"
								>
									{{ option.DdItemContentValue }}
								</el-radio>
							</el-radio-group>
						</template>
						<template v-if="item.DdItemTypeValue === 'cascader'">
							<el-cascader
									v-model="form[item.DdItemContentKey].value"
									:options="item.Data.Children"
									@change="handleChange"
									clearable
									style="width: 40%"
									:props="{ value: 'Code', label: 'Name', children: 'Children', checkStrictly: true}"
							></el-cascader>
						</template>
						<template v-if="item.DdItemTypeValue === 'checkbox'">
							<el-checkbox-group v-model="form[item.DdItemContentKey].value">
								<el-checkbox
										v-for="(option, optionIndex) in item.Children"
										:key="optionIndex"
										:label="option.DdItemContentKey"
								>
									{{ option.DdItemContentValue }}
								</el-checkbox>
							</el-checkbox-group>
						</template>
						<template v-if="item.DdItemTypeValue === 'input'">
							<el-input
									v-model="form[item.DdItemContentKey].value"
									style="width: 40%"
							/>
						</template>
						<template v-if="item.DdItemTypeValue === 'radioInput'">
							<el-radio-group v-model="form[item.DdItemContentKey].value">
								<el-radio
										v-for="(option, optionIndex) in item.Children"
										:key="optionIndex"
										:label="option.DdItemContentKey"
								>
									{{ option.DdItemContentValue }}
								</el-radio>
							</el-radio-group>
							<br />
							<el-input
									type="textarea"
									:rows="4"
									v-model="form[item.DdItemContentKey].remark"
									style="width: 40%"
							/>
						</template>
						<template v-if="item.DdItemTypeValue === 'picker'">
							<el-date-picker
									v-model="form[item.DdItemContentKey].value"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									format="yyyy-MM-dd"
									style="width: 40%"
							/>
						</template>
						<template v-if="item.DdItemTypeValue === 'select'">
							<el-select v-model="form[item.DdItemContentKey].value" placeholder="请选择" style="width: 40%">
								<el-option
										v-for="item in item.Children"
										:key="item.Id"
										:label="item.DdItemContentValue"
										:value="item.DdItemContentKey">
								</el-option>
							</el-select>
						</template>
						<template v-if="item.DdItemTypeValue === 'uploader'">
							<el-upload
									:action="actionUrl"
									:headers="headers"
									ref="upload"
									:before-upload="beforeUpload"
									:on-preview="handlePictureCardPreview"
									:on-success="(response, file) => handle_success(response, file, item)"
									:show-file-list="false"
									list-type="picture-card"
									:on-remove="handleRemove2"
									:on-change="handleChange"
							
							>
								<img v-if="form[item.DdItemContentKey].value"  :src="form[item.DdItemContentKey].value"  class="avatar"  alt="#"/>
								<i v-else  class="el-icon-plus"></i>
							</el-upload>
						</template>
						<template v-if="item.DdItemTypeValue === 'elButton'">
							<el-popover
									placement="right"
									width="300"
									trigger="click"
									popper-class="popperClass"
							>
								<div style="height: 350px;display: flex;align-items: center;justify-content: center;">
									
									<div style="">
										<el-image :src="httpsUrl" style="width: 300px; height: 300px;"></el-image>
									</div>
								</div>
								<div style="margin-left: 40%;">
									<el-button type="text" @click="downloadUrl">保存二维码</el-button>
									<!--							<a href="javascript:" @click="downloadUrl">下载二维码</a>-->
								</div>
								<el-button slot="reference"  class="circle-button" type="text" @click="">查看二维码</el-button>
							</el-popover>
						</template>
					
					</el-form-item>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</div>
			</div>
		</div>
		<el-button type="primary" style="margin-left: 25%" @click="submitForm">提交</el-button>
	</el-form>
</template>
<script>
import axios from "axios";
import {
	getInitialedProjectSettingsItem,
	addProjectSettings,
	uploadTempPic,
} from "../../api/api";
import Toolbar from "../../components/Toolbar.vue";
import { getButtonList } from "../../promissionRouter";
import { filterAsyncRouter } from "@/router/index";
import router from "@/router";

export default {
	name: 'querySettings',
	components: {},
	props: {
		// formItems: {
		// 	type: Array,
		// 	default: () => [],
		// },
	},
	data() {
		return {
			isShowDialog:true,
			qrCode:'',
			httpsUrl:'https://ssttest.wanghuolou.cn/sstwebapi/image/qrcode?url=https://ssttest.wanghuolou.cn?projectId='+window.localStorage.getItem('projectId'),
			BackgroundWebImgUrl:'',
			fileList: [],
			actionUrl:undefined,
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
			form: {},
			formItems: [],
			imageUrl:'',
			isToChange: false,
			isShowChange:true,
		};
	},
	watch: {
		'isShowChange':function (newVal, oldVal){
			if (!newVal){
				this.form[8].value=[]
				this.form[38].value= 0
			}
		},
		'formItems': function (newVal, oldVal){
			this.formItems.forEach(item=>{
				if (item.DdItemContentValue === '项目设置'){
					item.Children.forEach(value=>{
						if (value.DdItemContentValue === '是否考培分离'){
							if (value.IntegerContent === 3){
								this.isShowDialog = false;
							}else {
								this.isShowDialog = true;
							}
						}
					})
				}
			})
		}
	},
	created() {
		this.actionUrl = uploadTempPic;
		this.getFormDataList();
	},
	computed: {
		headers() {
			return{
				"Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
			}
		}
},
	mounted() {
	},
	methods: {
		changeValue(name,value){
			console.log(name,value,'22222')
			if (name === '是否考培分离'){
				this.isShowDialog = value != 3;
			}
			
			if (name.DdItemContentValue === '考试须知'&& value==1){
				this.form[name.DdItemContentKey].value = 1
				this.form[name.DdItemContentKey].remark = name.Children[0].StringContent;
				this.isToChange = true;
			}else if (name.DdItemContentValue === '考试须知'&& value==2){
				this.form[name.DdItemContentKey].value = 2
				this.form[name.DdItemContentKey].remark = name.StringContent;
				this.isToChange = false;
			}
			
			this.isShowChange = !(name === '报考照片录入' && value === 2);
			
		},
		changeValueBox(name,value){
			// console.log(value,'value3333333')
			console.log(value,'value3333333')
			if (name === ''){
			
			}
		},
		async downloadUrl(){
			console.log(this.httpsUrl,'httpsUrl')
			// window.open(this.httpsUrl)
			const imageUrl = this.httpsUrl;
			const newWindow = window.open(imageUrl, '_blank'); // 打开新窗口
			
			// 在新窗口加载完成后执行下载操作
			newWindow.onload = function () {
				const link = newWindow.document.createElement('a');
				link.href = imageUrl;
				link.download = 'image.jfif'; // 指定下载时保存的文件名
				newWindow.document.body.appendChild(link); // 将链接暂时添加到新窗口的DOM
				link.click(); // 自动点击触发下载
				newWindow.document.body.removeChild(link); // 移除链接
			};
			// window.location.href=this.httpsUrl
		},
		getToken() {
			return localStorage.getItem("Token")
		},
		// 图片上传之前函数
		beforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 > 2;
			var type = file.name.substring(file.name.lastIndexOf(".")+1);
			if (type == 'jpg' || type == 'png') {} else {
				this.$message.error('上传图片只能是 JPG/PNG 格式!');
				return false;
			}
			if (!isLt2M === false) {
				this.$message.error('上传图片大小不能超过 2MB!');
				return false
			}
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		// 图片上传成功时的函数
		handle_success(res,file, item) {
			console.log(res,file,item,'jjjjjjjjjjjjjjjjjj')
			if(res.Success) {
				this.$message.success('图片上传成功')
				this.imageUrlData = res.Response
				this.BackgroundWebImgUrl = res.Response.weburl
				// this.imageUrl = res.Response.webupurl
				this.form[item.DdItemContentKey].value = res.Response.weburl
				console.log(this.imageUrl,'imageUrl')
			}else{
				this.$message.error(res.Message)
			}
		},
		handleRemove2(file, fileList) {
			this.imageUrlData = ''
		},
		
		
		handleChange(val){
			console.log('创建项目-级联选择器',val)
		},
		submitForm() {
			const formData = {};
			for (const key in this.form) {
				if (this.form.hasOwnProperty(key)) {
					formData[key] = {
						Value: this.form[key].value,
						ParentNamespace: this.form[key].ParentNamespace,
						Remarks: this.form[key].remark,
						DdItemContentKey: this.form[key].DdItemContentKey,
						DdItemContentNamespace: this.form[key].DdItemContentNamespace,
						DdItemTypeValue: this.form[key].DdItemTypeValue,
					};
					let newValueName;
					if (this.form[key].DdItemTypeValue === "picker") {
						newValueName = "ProjectTimeList";
						this.$set(this.form[key], newValueName, {
							projectTimeList: this.form[key].value,
						});
					}
					if (this.form[key].DdItemTypeValue === "cascader") {
            const cascaderValue = this.form[key].value === null ? [] : this.form[key].value;
            this.form[key].value = cascaderValue;
						newValueName = "AreaList";
						this.$set(this.form[key], newValueName, {
							areaList: cascaderValue,
						});
            formData[key].Value = cascaderValue;
					}
					if (this.form[key].DdItemTypeValue === "checkbox") {
						newValueName = "ExamAntiCheatList";
						this.$set(this.form[key], newValueName, {
							examAntiCheatList: this.form[key].value,
						});
					}
					if (this.form[key].DdItemTypeValue === "uploader") {
						console.log(this.form[key],'keyeeeee ');
						// this.$set(this.form[key], 'value', this.imageUrl)
						// formData[key].Value = this.BackgroundWebImgUrl
						formData[key].Value = this.form[key].value?this.form[key].value.split('image/')[1]:''
						// this.$set(this.form[key], newValueName, {
						// 	webupurl: this.BackgroundWebImgUrl,
						// });
					}
				}
			}
			let params = [];
			for (const key in formData) {
				if (formData.hasOwnProperty(key)) {
					const element = formData[key];
					params.push(element);
				}
			}
			let res = {
				ProjectSettingsList: params,
			};
			const id = window.localStorage.getItem("projectId");
			console.log(parseInt(id),'id')
			res.ProjectId = parseInt(id);
			addProjectSettings(res).then((res) => {
				if (res.data.Success) {
					console.log(res.data.Response);
					this.getFormDataList()
					this.$message.success(res.data.Message);
				}else {
					this.$message.error(res.data.Message);
				}
			});
			console.log("表单数据：", formData);
		},
		getFormData(items) {
			if (items) {
				items.forEach((item) => {
					item.Children.forEach((itemElement) => {
						if (itemElement.Children && itemElement.Children.length > 0) {
							const children = itemElement.Children[0];
							this.$set(this.form, itemElement.DdItemContentKey, {
								value: [],
								remark: children.Remarks,
								DdItemContentKey: itemElement.DdItemContentKey,
								ParentNamespace: children.ParentNamespace,
								DdItemContentNamespace: children.DdItemContentNamespace,
								DdItemTypeValue: children.DdItemTypeValue,
							});
							if (itemElement.DdItemTypeValue === 'radio'){
								if(itemElement.DdItemContentValue === '报考照片录入'&&itemElement.IntegerContent===2){
									this.isShowChange=false
									// console.log(itemElement,'djdjjjjjjjjjjjjj')
								}
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent);
								if(itemElement.DdItemContentValue === '配送教材'){
									this.$set(this.form[itemElement.DdItemContentKey], 'value',  itemElement.IntegerContent?itemElement.IntegerContent:2);
								}
							}
							if (itemElement.DdItemTypeValue === 'uploader'){
								if (itemElement.StringContent){
									this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringContent);
									console.log(this.form[itemElement.DdItemContentKey].value,99999999999999)
								}
							}
							if (itemElement.DdItemTypeValue === 'input'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringContent);
							}
							if (itemElement.DdItemTypeValue === 'cascader'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringListContent);
							}
							if (itemElement.DdItemTypeValue === 'picker'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringListContent);
							}
							if (itemElement.DdItemTypeValue === 'radioInput'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent);
								this.$set(this.form[itemElement.DdItemContentKey], 'remark', itemElement.StringContent);
							}
							if (itemElement.DdItemTypeValue === 'select'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent);
							}
							if (itemElement.DdItemTypeValue === 'checkbox'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerListContent===null?[]:itemElement.IntegerListContent);
							}
						} else {
							this.$set(this.form, itemElement.DdItemContentKey, {
								value: [],
								remark: itemElement.Remarks,
								DdItemContentKey: itemElement.DdItemContentKey,
								ParentNamespace: itemElement.ParentNamespace,
								DdItemContentNamespace: itemElement.DdItemContentNamespace,
								DdItemTypeValue: itemElement.DdItemTypeValue,
							});
							if (itemElement.DdItemTypeValue === 'uploader'){
								if (itemElement.StringContent){
									this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringContent);
									console.log(this.form[itemElement.DdItemContentKey].value,99999999999999)
								}
							}
							if (itemElement.DdItemTypeValue === 'radio'){
								if(itemElement.DdItemContentValue === '报考照片录入'&&itemElement.IntegerContent===2){
									this.isShowChange=false
									// console.log(itemElement,'djdjjjjjjjjjjjjj')
								}
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent);
								if(itemElement.DdItemContentValue === '配送教材'){
									this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent?itemElement.IntegerContent:2);
								}
							}
							if (itemElement.DdItemTypeValue === 'select'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent);
							}
							if (itemElement.DdItemTypeValue === 'checkbox'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerListContent===null?[]:itemElement.IntegerListContent);
							}
							if (itemElement.DdItemTypeValue === 'input'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringContent);
							}
							if (itemElement.DdItemTypeValue === 'cascader'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringListContent);
							}
							if (itemElement.DdItemTypeValue === 'picker'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.StringListContent);
							}
							if (itemElement.DdItemTypeValue === 'radioInput'){
								this.$set(this.form[itemElement.DdItemContentKey], 'value', itemElement.IntegerContent);
								this.$set(this.form[itemElement.DdItemContentKey], 'remark', itemElement.StringContent);
							}
						}
					});
				});
			}
		},
		getFormDataList() {
			const id = window.localStorage.getItem("projectId");
			let params = {
				projectId:id,
			};
			getInitialedProjectSettingsItem(params).then((res) => {
				if (res.data.Success) {
					this.formItems = res.data.Response.Children;
					this.getFormData(this.formItems);
					console.log(this.formItems,'this.formItems')
				}
			});
		},
	}
}
</script>

<style scoped lang="stylus">
.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 178px;
	height: 178px;
	line-height: 178px;
	text-align: center;
}
.avatar {
	width: 100%;
	height: 100%;
	display: block;
}
</style>
<script setup>
import download from "@/views/download.vue";
</script>